<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>

      <ion-tab-bar slot="bottom" v-if="store.state.showBottomBar">
        <ion-tab-button tab="tab_home" href="/tabs/tab_home">
          <ion-icon :icon="home" />
          <ion-label>Home</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab_activity" href="/tabs/tab_activity">
          <ion-icon :icon="footsteps" />
          <ion-label>Activity</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab_review" href="/tabs/tab_review">
          <ion-icon :icon="checkmarkDoneOutline" />
          <ion-label>Review</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab_profile" href="/tabs/tab_profile">
          <ion-icon :icon="personOutline" />
          <ion-label>Profile</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script setup>
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
//import { home, settings, chatbubbles, footsteps} from 'ionicons/icons';
//import { home, settings, mailOutline, apertureOutline, footsteps} from 'ionicons/icons';
import { home, personOutline, footsteps} from 'ionicons/icons';
import { checkmarkDoneOutline} from 'ionicons/icons';
//import { home, settings, map, person, chatbubbles } from 'ionicons/icons';

import store from '@/store/store.js';
store.commit('updateTabShowBottomBar', true);
//  store.commit('updateValue', doc_id);
//  store.commit('updateTab', doc_id);

//import state from '@/store/store.js';
//console.log('----- state from store.js:', state, state.showBottomBar);

</script>
