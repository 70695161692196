
import { createStore } from 'vuex';

const store = createStore({
	state: {
		showBottomBar: true,
		user: {},
		recordingBitrate: 48000,
		curr_doc_id: '',
		curr_page_index: 0,
	},
	mutations: {
		updateTabShowBottomBar(state, new_value) {
			state.showBottomBar = new_value;
			//console.log('<<-->> store.js (new tab showBottomBar):', state.showBottomBar);
		},
		updateCurrDocId(state, new_value) {
			state.curr_doc_id = new_value;
		},
		updateCurrPageIndex(state, new_value) {
			state.curr_page_index = new_value;
		},
		updateUser(state, new_value) {
			state.user = new_value;
			console.log('<<-->> store.js (update user):', state.user);
		},
		updateRecordingBitrate(state, new_value) {
			state.recordingBitrate = new_value;
			console.log('<<-->> store.js (update bitrate):', state.recordingBitrate);
		},
	}
});
export default store;


/*import { reactive } from 'vue';
export const store = reactive({
	user: {},
})
*/

/*export default {
  setup() {
    const state = reactive({
      showBottomBar: false,
    });
    
    // ... other code here
    
    return {
      state,
      // ... other variables here
    };
  },
};
*/
