<template>
  <ion-app>

  <div class="container" style="padding: 50px 0 100px 0">
    <div v-if="session" :session="session">
      <ion-router-outlet />
    </div>

    <div v-else style="padding:15px">
      <h3 class="header">Learn / Speak English <br>with AI Teacher</h3>
      <p class="description">Sign in with your email</p>
      <div>
        <ion-input :clear-input="true" type="email" placeholder="Enter your email here" v-model="email"></ion-input>
      </div>
      <div style="margin-top:10px; font-size:110%">
        <ion-button size="medium" :disabled="r_request_code_feedback.length>0" @click="request_code_for_signin">Email me a code</ion-button>
        <ion-item v-if="r_request_code_feedback.length>0"> <ion-text color="success">{{r_request_code_feedback}}</ion-text> </ion-item>

      </div>

      <div v-if="otp_request_is_submitted" class="input-button-container">
        <ion-input type="number" placeholder="Enter the code here" v-model="otp"></ion-input>
        <ion-button size="medium" :disabled="r_verify_code_feedback.length>0" @click="verify_otp_code">Verify</ion-button>
        <ion-item v-if="r_verify_code_feedback.length>0"> <ion-text color="success">{{r_verify_code_feedback}}</ion-text> </ion-item>
      </div>
    </div>

  </div>

  </ion-app>

</template>

<script setup>
/*
    <div v-if="session" :session="session">
    <div v-if="true" :session="session">
    <ion-router-outlet />
    */
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { onMounted, ref } from 'vue'
import { IonButton, IonInput, IonText, IonItem} from '@ionic/vue';
import store from '@/store/store.js';

const r_request_code_feedback = ref('');
const r_verify_code_feedback = ref('');
const email = ref('')
const otp = ref('')

const api_server_url = `https://newxyy.langev.com/apiV2/`;
async function post_request_(options, task='generate_otp') {
    let url = api_server_url + task + '/';
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', },
      body: JSON.stringify(options),
    })
    return await response.json();
}

const otp_request_is_submitted = ref(false);
//const otp_request_is_submitted = ref(true);

const request_code_for_signin = async () => {
  r_request_code_feedback.value = 'Please wait...';

  let options = { user_email: email.value};
  let data = await post_request_(options, 'generate_otp');
  console.log('\n- result from generate_otp:', data);
  if (data.message.includes('Please try again later')) {
    r_request_code_feedback.value = 'Rate limit exceeded. Please try again later.';
    return;
  } else {
    r_request_code_feedback.value = 'Check your email for the code';
    otp_request_is_submitted.value = true;
  }
}

const verify_otp_code = async () => {
  r_verify_code_feedback.value = 'Please wait...';
  let options = { user_email: email.value, user_otp: otp.value};
  let data = await post_request_(options, 'verify_otp');
  console.log('\n- result from verify_otp:', data);
  post_process_login(data);
  r_request_code_feedback.value = '';
  r_verify_code_feedback.value = '';
}

const post_process_login = async (data) => {
  if (data?.status == 'success') {
    console.log('You are logged in!');
    localStorage.setItem('user_id', data.uid);
    store.user = {id: data.uid}
    session.value = { user_id: data.uid};
  } else {
    console.log('Wrong code!');
    store.user = {}
  }
}

const check_local_storage_for_user_id = async () => {
  let user_id = localStorage.getItem('user_id');
  if (user_id && user_id.length > 30) {
    //let options = {user_id: user_id, token: 'todo'};
    let options = {uid: user_id};
    let data = await post_request_(options, 'verify_user_token');
    console.log('\n- result from verify_user_token:', data);
    post_process_login(data);
    /*if (data?.status == true) {
      console.log('You are logged in!', user_id);
      store.user = {id: user_id}
      //store.commit('updateUser', {uid: user_id});
      session.value = {user_id: user_id};
    } else {
      console.log('not logged in');
      store.user = {}
    }
    */
  }
}

const session = ref();

onMounted(() => {
  //const uid = 'GOa7bDUupLrMphiUNy1nASx4iwV55naL'
  //localStorage.setItem('user_id', uid);
  check_local_storage_for_user_id();

  /*
  supabase.auth.getSession().then(({ data }) => {
    session.value = data.session;

    store.user = data.session?.user ?? {};
    //store.access_token = data.session?.access_token ?? '';
    //store.user = supabase.auth.user() ?? {};
    //console.log('- already authorized: store.user:', store.user);
    //console.log('- already authorized: access_token:', store.access_token);
  })

  supabase.auth.onAuthStateChange((_, _session) => {
    session.value = _session;
    //console.log(_session);
    store.user = _session?.user ?? {};
    //console.log('- onAuthStateChange: store.user:', store.user);
  })
  */

})

</script>


<style scoped>
.input-button-container {
  margin:10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>